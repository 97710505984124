import Vue from 'vue'
import VueRouter from 'vue-router'
import OidcSigInCallBack from '../views/OidcSignInCallBack.vue'
import OidcSignOutCallBack from '../views/OidcSignOutCallBack.vue'

import { loginCallback, logoutCallback } from '../config/config'
import Oidc from '../auth/auth'

const oidc = new Oidc()
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
      // if user is logged in, let them into to home
      async beforeEnter(to, from, next) {
        console.log('beforeEnter called: ' + from.path + ' -> ' + to.path);
        try {
          var alreadyLoggedIn = router.app.$store.state.auth.IsLoggedIn; 
          
          if (alreadyLoggedIn === null || alreadyLoggedIn === false)
          {
            alreadyLoggedIn = await oidc.isLoggedIn();
          }

          // console.log('authUser', auth)
          if (alreadyLoggedIn) {
            next('/home')
          } else {
            next()
          }
        } catch (e) {
          // console.log('error', e.message)
          next()
        }
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        requiresAuth: true,
      },
    },
    {
      path: '/index.html',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        requiresAuth: true,
      },
    },
    {
      path: '/search-result',
      name: 'SearchResult',
      component: () => import('@/views/cases/SearchResult.vue'),
      meta: {
        pageTitle: 'SearchResult',
        requiresAuth: true,
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/Admin.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Admin',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Admin',
      },
    },
    {
      path: '/view-profile',
      name: 'view-profile',
      component: () => import('@/views/user/ViewProfile.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View Profile',
      },
    },
    {
      path: '/admin/utility',
      name: 'admin-utility',
      component: () => import('@/views/admin/Utility.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Utility',
      },
    },
    {
      path: '/admin/users-and-permissions',
      name: 'admin-users-and-permissions',
      component: () => import('@/views/admin/UsersAndPermissions.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Utility',
      },
    },
    {
      path: '/admin/utility/journal-categories',
      name: 'journal-categories',
      component: () => import('@/views/admin/JournalCategories.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Journal Categories',
      },
    },
    {
      path: '/admin/utility/notifications',
      name: 'notifications',
      component: () => import('@/views/admin/Notifications.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Journal Categories',
      },
    },
    {
      path: '/admin/utility/admin-dashboard',
      name: 'admin-dashboard',
      component: () => import('@/views/admin/AdminDashboard.vue'),
    },
    {
      path: '/admin/users-and-permissions/users',
      name: 'admin-users',
      component: () => import('@/views/admin/UsersList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Users Table',
      },
    },
    {
      path: '/admin/users-and-permissions/user-roles',
      name: 'user-roles',
      component: () => import('@/views/admin/UserRoles.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Users Roles',
      },
    },
    {
      path: '/admin/users-and-permissions/users/add',
      name: 'add-new-user',
      component: () => import('@/views/admin/UserEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add New User',
      },
    },
    {
      path: '/admin/users-and-permissions/users/edit/:userId',
      name: 'edit-user',
      component: () => import('@/views/admin/UserEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit User',
      },
    },
    {
      path: '/hr/users',
      name: 'hr-users',
      component: () => import('@/views/admin/UsersList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Users Table',
        forHR: true
      },
    },
    {
      path: '/hr/users/edit/:userId',
      name: 'hr-edit-user',
      component: () => import('@/views/admin/UserEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit User',
        forHR: true
      },
    },

    {
      path: '/admin/users-and-permissions/permission-sets/edit/:permissionsetid',
      name: 'edit-permission-set',
      component: () => import('@/views/admin/PermissionSetEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add New Permission Set',
      },
    },
    {
      path: '/admin/users-and-permissions/permission-sets/copy/:permissionsetid',
      name: 'copy-permission-set',
      component: () => import('@/views/admin/PermissionSetEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Copy Permission Set',
        isCopy: true
      },
    },
    {
      path: '/admin/users-and-permissions/permission-sets/add',
      name: 'add-permission-set',
      component: () => import('@/views/admin/PermissionSetEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add New Permission Set',
      },
    },
    {
      path: '/admin/users-and-permissions/permission-sets',
      name: 'view-permission-sets',
      component: () => import('@/views/admin/PermissionSetsList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Permission Sets',
      },
    },
    {
      path: '/admin/templates',
      name: 'templates',
      component: () => import('@/views/admin/Templates.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Templates',
      },
    },
    {
      path: '/admin/template/journal-email-template',
      name: 'journal-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "journal",
        requiresAuth: true,
        pageTitle: 'Journal Email Template',
      },
    },
    {
      path: '/admin/template/desktop-email-template',
      name: 'desktop-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "desktopTemplate",
        requiresAuth: true,
        pageTitle: 'Desktop Email Template',
      },
    },
    {
      path: '/admin/template/journal-desktop-update-email-template',
      name: 'journal-desktop-update-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "desktop",
        requiresAuth: true,
        pageTitle: 'Desktop Update Template',
      },
    },
    {
      path: '/admin/template/internal-journal-email-template',
      name: 'internal-journal-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "internal",
        requiresAuth: true,
        pageTitle: 'Internal Journal Email Template',
      },
    },
    {
      path: '/admin/template/journal-collections-email-template',
      name: 'journal-collections-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "collections",
        requiresAuth: true,
        pageTitle: 'Journal Collections Email Template',
      },
    },
    {
      path: '/admin/template/journal-daily-update-email-template',
      name: 'journal-daily-update-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "dailyUpdate",
        requiresAuth: true,
        pageTitle: 'Journal Daily Update',
      },
    },
    {
      path: '/admin/template/journal-final-report-email-template',
      name: 'journal-final-report-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "finalReport",
        requiresAuth: true,
        pageTitle: 'Journal Final Report',
      },
    },
    {
      path: '/admin/template/operations-manager-notification',
      name: 'operations-manager-notification-template',
      component: () => import('@/views/admin/EditActivityUpdateTemplate.vue'),
      meta: {
        templateId: "operations-manager-notification-template",
        requiresAuth: true,
        pageTitle: 'Operations Manager Notification',
      },
    },
    {
      path: '/admin/template/public-case-notification-template',
      name: 'public-case-notification-template',
      component: () => import('@/views/admin/PublicCaseNotificationTemplate.vue'),
      meta: {
        templateId: "public-case-notification-template",
        requiresAuth: true,
        pageTitle: 'Public Case Notification Template',
      },
    },
    {
      path: '/admin/template/reopen-email-template',
      name: 'reopen-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "reopen",
        requiresAuth: true,
        pageTitle: 'Reopen Email Template',
      },
    },
    {
      path: '/admin/template/license-reminder-email-template',
      name: 'license-reminder-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "license",
        requiresAuth: true,
        pageTitle: 'License Reminder Email Template',
      },
    },
    {
      path: '/admin/template/activity-email-template',
      name: 'activity-email-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "activity",
        requiresAuth: true,
        pageTitle: 'Activity Email Template',
      },
    },
    {
      path: '/admin/template/client-update-template',
      name: 'client-update-template',
      component: () => import('@/views/admin/CreateCaseUpdateEmailTemplate.vue'),
      meta: {
        template: "client",
        requiresAuth: true,
        pageTitle: 'Client Update Template',
      },
    },
    {
      path: '/admin/template/global-email-template',
      name: 'global-email-template',
      component: () => import('@/views/admin/EditGlobalEMailTemplate.vue'),
      meta: {
        templateId: "global-email-template",
        requiresAuth: true,
        pageTitle: 'Global E-Mail Template',
      },
    },
    {
      path: '/admin/template/activity-update-template',
      name: 'activity-update-template',
      component: () => import('@/views/admin/EditActivityUpdateTemplate.vue'),
      meta: {
        templateId: "activity-update-template",
        requiresAuth: true,
        pageTitle: 'Activity Update Template',
      },
    },
    {
      path: '/admin/template/doc-report-template',
      name: 'doc-report-template',
      component: () => import('@/views/admin/DocumentReportTemplates.vue'),
      meta: {
        templateId: "activity-update",
        requiresAuth: true,
        pageTitle: 'Document Report Templates',
      },
    },
    {
      path: '/admin/utility/manage-action-types',
      name: 'manage-action-types',
      component: () => import('@/views/admin/ManageActionTypes.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manage Action Types',
      },
    },
    {
      path: '/admin/utility/add-action-types',
      name: 'add-action-types',
      component: () => import('@/views/admin/AddActionType.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Action Types',
      },
    },
    {
      path: '/admin/utility/change-case-state',
      name: 'change-case-state',
      component: () => import('@/views/admin/ChangeCaseState.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Change Case State',
      },
    },
    {
      path: '/admin/utility/public-case-password',
      name: 'public-case-password',
      component: () => import('@/views/admin/PublicCasePDFPassword.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Public Case PDF Password',
      },
    },
    {
      path: '/admin/utility/change-case-status',
      name: 'change-case-status',
      component: () => import('@/views/admin/ChangeCaseStatus.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Change Case Status',
      },
    },
    {
      path: '/admin/utility/review-user-session-activity',
      name: 'review-user-session-activity',
      component: () => import('@/views/admin/ReviewUserSessionActivity.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Review User Session Activity',
      },
    },
    {
      path: '/admin/utility/manage-vehicles',
      name: 'manage-vehicles',
      component: () => import('@/views/admin/ManageVehicles.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manage Vehicles',
      },
    },
    {
      path: '/admin/utility/impact-rating-editor',
      name: 'impact-rating-editor',
      component: () => import('@/views/admin/ImpactRatingEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Impact Rating Editor',
      },
    },
    {
      path: '/admin/utility/add-vehicle',
      name: 'add-vehicle',
      component: () => import('@/views/admin/AddVehicle.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Vehicle',
      },
    },
    {
      path: '/admin/utility/add-impact-rating',
      name: 'add-impact-rating',
      component: () => import('@/views/admin/AddImpactRating.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Impact Rating',
      },
    },
    {
      path: '/admin/utility/edit-vehicle/:vehicleID',
      name: 'edit-vehicle',
      component: () => import('@/views/admin/AddVehicle.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Vehicle',
      },
    },
    {
      path: '/admin/utility/edit-impact-rating/:ImpactRatingID',
      name: 'edit-impact-rating',
      component: () => import('@/views/admin/AddImpactRating.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Impact Rating',
      },
    },
    {
      path: '/admin/utility/edit-action-types/:actionTypeID',
      name: 'edit-action-types',
      component: () => import('@/views/admin/AddActionType.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Action Types',
      },
    },
    {
      path: '/clients',
      name: 'clients-view-list',
      component: () => import('@/views/client/ClientsList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Clients Listing',
      },
    },
    {
      path: '/admin/utility/client',
      name: 'client-merge-delete',
      component: () => import('@/views/client/MergeClients.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Clients/Insured',
      },
    },
    {
      path: '/admin/utility/merge-users',
      name: 'merge-users',
      component: () => import('@/views/admin/MergeUsers.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Merge Users',
      },
    },
    {
      path: '/licenses/manager-members',
      name: 'manager-members',
      component: () => import('@/views/admin/ManagerMembers.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Members',
      },
    },
    {
      path: '/admin/utility/merge-client-users',
      name: 'merge-client-users',
      component: () => import('@/views/admin/MergeClientUsers.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Merge Client',
      },
    },
    {
      path: '/accounting/invoice-summary-list',
      name: 'invoice-summary-list',
      component: () => import('@/views/accounting/InvoiceSummaryList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Invoice Summary List',
      },
    },
    {
      path: '/accounting/invoice-summary/:invoiceID',
      name: 'invoice-summary',
      component: () => import('@/views/accounting/InvoiceSummary.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Invoice Summary',
      },
    },
    {
      path: '/accounting/change-invoice-number',
      name: 'change-invoice-number',
      component: () => import('@/views/accounting/ChangeInvoiceNumber.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Change Invoice Number',
      },
    },
    {
      path: '/accounting/bulk-invoice-printing',
      name: 'bulk-invoice-printing',
      component: () => import('@/views/accounting/BulkInvoicePrinting.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Bulk Invoice Printing',
      },
    },
    {
      path: '/clients/add',
      name: 'add-new-client',
      component: () => import('@/views/client/ClientsEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add new client',
      },
    },
    {
      path: '/clients/edit/:clientID',
      name: 'edit-client',
      component: () => import('@/views/client/ClientsEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit client',
      },
    },
    {
      path: '/clients/view/:clientID',
      name: 'view-client',
      component: () => import('@/views/client/ClientsEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View client',
        view: true,
      },
    },
    {
      path: '/clients/:clientID/branch/add',
      name: 'add-branch',
      component: () => import('@/views/client/BranchEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Branch',
      },
    },
    {
      path: '/clients/:clientID/branch/:branchID/edit',
      name: 'edit-branch',
      component: () => import('@/views/client/BranchEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Branch',
      },
    },
    {
      path: '/client/requestor/add/:clientID/:branchID',
      name: 'add-requester',
      component: () => import('@/views/client/RequesterEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add requestor',
      },
    },
    {
      path: '/client/requestor/:requestorID/edit/:clientID/:branchID',
      name: 'edit-requester',
      component: () => import('@/views/client/RequesterEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit requester',
      },
    },
    {
      path: '/client/requestor/:requestorID/view/:clientID/:branchID',
      name: 'view-requester',
      component: () => import('@/views/client/RequesterEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View requester',
        view: true,
      },
    },
    {
      path: '/insured',
      name: 'insured-view-list',
      component: () => import('@/views/client/InsuredList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Insured Listing',
      },
    },
    {
      path: '/insured/add',
      name: 'add-new-insured',
      component: () => import('@/views/client/InsuredEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add new insured',
      },
    },
    {
      path: '/insured/edit/:insuredID',
      name: 'edit-insured',
      component: () => import('@/views/client/InsuredEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit insured',
      },
    },
    {
      path: '/insured/view/:insuredID',
      name: 'view-insured',
      component: () => import('@/views/client/InsuredEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View insured',
        view: true,
      },
    },
    {
      path: '/cases',
      name: 'cases-view-list',
      component: () => import('@/views/cases/CasesList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Cases Listing',
      },
    },
    {
      path: '/licenses',
      name: 'licenses',
      component: () => import('@/views/licenses/LicensesList.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Licenses',
      },
    },
    {
      path: '/mycases',
      name: 'my-cases-view-list',
      component: () => import('@/views/mycases/MyCases.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'My Cases Listing',
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reporting/Reports.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Reports',
      },
    },
    {
      path: '/reminders',
      name: 'reminders',
      component: () => import('@/views/cases/Reminders.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Reminders',
      },
    },
    {
      path: '/licenses/license-editor/:userID',
      name: 'license-editor',
      component: () => import('@/views/licenses/LicenseEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'License editor',
      },
    },
    {
      path: '/subject/:subjectID',
      name: 'subject-view',
      component: () => import('@/views/cases/Subject.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View Subject',
      },
    },
    {
      path: '/cases/add',
      name: 'add-new-case',
      component: () => import('@/views/cases/CaseEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add new Case',
      },
    },
    {
      path: '/cases/add/public',
      name: 'add-new-case-public',
      component: () => import('@/views/cases/public/CaseEditor.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'New Case (Public Submission)',
      },
    },
    {
      path: '/cases/add/public2',
      name: 'add-new-case-public',
      component: () => import('@/views/cases/public/CaseEditor2.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'New Case (Public Submission)',
      },
    },
    {
      path: '/cases/add-activity/:actionID',
      name: 'add-activity',
      component: () => import('@/views/cases/AddActivity.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Activity',
      },
    },
    {
      path: '/cases/action/videos/:actionID',
      name: 'videos',
      component: () => import('@/views/cases/VideoFromAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Videos',
      },
    },
    {
      path: '/cases/activity/video/:videoID/:actionID/:duration',
      name: 'video-details',
      component: () => import('@/views/cases/components/VideoDetails.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Video details',
      },
    },
    {
      path: '/cases/activity/videos/:activityID',
      name: 'activity-videos',
      component: () => import('@/views/cases/VideoFromAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Videos',
      },
    },
    {
      path: '/cases/edit-activity/:activityID',
      name: 'edit-activity',
      component: () => import('@/views/cases/AddActivity.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Activity',
      },
    },
    {
      path: '/cases/view-activity/:activityID/:keyTab?',
      name: 'view-activity',
      component: () => import('@/views/cases/ViewActivity.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View Activity',
      },
    },
    {
      path: '/cases/add-expenses/:activityID',
      name: 'add-expenses',
      component: () => import('@/views/cases/AddExpense.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Expenses',
      },
    },
    {
      path: '/cases/edit-expenses/:expenseID',
      name: 'edit-expenses',
      component: () => import('@/views/cases/AddExpense.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Expenses',
      },
    },
    {
      path: '/cases/upload-video-clip/:activityID',
      name: 'upload-video-clip',
      component: () => import('@/views/cases/UploadVideoClip.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Upload Video Clip',
      },
    },
    {
      path: '/cases/reassign-case/:caseID',
      name: 'reassign-case',
      component: () => import('@/views/cases/ReassignCase.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Reassign Case',
      },
    },
    {
      path: '/reassign-investigator/:activityID/:actionID',
      name: 'reassign-investigator',
      component: () => import('@/views/cases/ReassignInvestigator.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Reassign Investigator',
      },
    },
    {
      path: '/cases/add-journal-entry',
      name: 'add-journal-entry',
      component: () => import('@/views/cases/AddJournalEntry.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add journal entry',
      },
    },
    {
      path: '/scopes',
      name: 'view-user-scopes',
      component: () => import('@/views/Scopes.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View User Scopes',
      },
    },
    {
      path: '/cases/edit-journal-entry/:journalEntryID',
      name: 'edit-journal-entry',
      component: () => import('@/views/cases/AddJournalEntry.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit journal entry',
        edit: true,
      },
    },
    {
      path: '/cases/view-journal-entry/:journalEntryID',
      name: 'view-journal-entry',
      component: () => import('@/views/cases/AddJournalEntry.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View journal entry',
        view: true,
      },
    },
    {
      path: '/cases/approve-action/:actionID',
      name: 'approve-action',
      component: () => import('@/views/cases/ApproveAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Approve Action',
      },
    },
    {
      path: '/cases/add-action/:caseID',
      name: 'add-action',
      component: () => import('@/views/cases/AddAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Action',
      },
    },
    {
      path: '/cases/edit-action/:actionID',
      name: 'edit-action',
      component: () => import('@/views/cases/EditAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Action',
      },
    },
    {
      path: '/mycases/cases/edit-action/:actionID',
      name: 'edit-my-cases-action',
      component: () => import('@/views/cases/EditAction.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Action',
        myCases: true,
      },
    },
    {
      path: '/cases/case-log-history/:caseId',
      name: 'case-log-history',
      component: () => import('@/views/cases/CaseLogHistory.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Case Log History',
      },
    },
    {
      path: '/cases/create-case-update-email/:caseId/:journalEntryID?',
      name: 'create-case-update-email',
      component: () => import('@/views/cases/CreateCaseUpdateEmail.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Create case update email',
      },
    },
    {
      path: '/cases/email-case-update/:activityID',
      name: 'email-case-update',
      component: () => import('@/views/cases/EmailCaseUpdate.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Email Case Update',
      },
    },
    {
      path: '/cases/:caseId/detail/:keyTab?',
      name: 'view-case',
      component: () => import('@/views/cases/CaseEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View Case',
      },
    },
    {
      path: '/mycases/cases/:caseId/detail/:keyTab?',
      name: 'view-my-cases-case',
      component: () => import('@/views/cases/CaseEditor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View Case',
        myCases: true,
      },
    },
    {
      path: '/cases/close-reopen/:caseId',
      name: 'cases-close-reopen',
      component: () => import('@/views/cases/CloseReopenCase.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Cases close reopen',
      },
    },
    {
      path: '/cases/budget-adjustment/:caseId',
      name: 'cases-budget-adjustment',
      component: () => import('@/views/cases/NewBudgetAdjustment.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'New Case Budget Adjustment',
      },
    },
    {
      path: '/cases/export-report/:caseId',
      name: 'export-report',
      component: () => import('@/views/cases/ExportReport.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Export report',
      },
    },
    {
      path: '/cases/case-video/:caseId',
      name: 'case-video',
      component: () => import('@/views/cases/CaseVideo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Case Video',
      },
    },
    {
      path: '/cases/merge-case-video/:caseId',
      name: 'case-video',
      component: () => import('@/views/cases/MergeCaseVideo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Merge Case Video',
      },
    },
    {
      path: '/cases/case-media/:caseId',
      name: 'case-media',
      component: () => import('@/views/cases/CaseMedia.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Case Media',
      },
    },
    {
      path: '/video/view/pub',
      name: 'case-video-public',
      component: () => import('@/views/cases/CaseVideoPublic.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Case Video',
      },
    },
    {
      path: '/cases/add-invoice/:caseId',
      name: 'add-invoice',
      component: () => import('@/views/cases/AddInvoice.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Invoice',
      },
    },
    {
      path: '/assignments/user/:userID/:date?',
      name: 'schedule',
      component: () => import('@/views/schedule/Schedule'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Schedule',
      },
    },
    {
      path: '/timesheet/user/:userID',
      name: 'timesheet',
      component: () => import('@/views/schedule/Timesheet'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Timesheet',
      },
    },
    {
      path: '/manager-scheduler',
      name: 'manager-scheduler',
      component: () => import('@/views/schedule/ManagerSchedules'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Schedules',
      },
    },
    {
      path: '/manager-scheduler/action/:actionID',
      name: 'manager-scheduler-action-edit',
      component: () => import('@/views/schedule/ManagerSchedules'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Schedules Action Edit',
      },
    },
    {
      path: '/manager-scheduler/action-readonly/:actionID',
      name: 'manager-scheduler-action-view',
      component: () => import('@/views/schedule/ManagerSchedules'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Schedules Action View',
      },
    },
    {
      path: '/manager-scheduler/user',
      name: 'manager-scheduler-user-edit',
      component: () => import('@/views/schedule/ManagerSchedules'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Schedules User Edit',
      },
    },
    {
      path: '/manager-scheduler/blank',
      name: 'manager-scheduler-user-blank',
      component: () => import('@/views/schedule/ManagerSchedulesBlank'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Manager Schedules User Edit',
      },
    },
    {
      path: '/cases/upload-activity-thumbnail/:actionID/:activityID',
      name: 'upload-activity-thumbnail',
      component: () => import('@/views/cases/UploadActivityThumbnail.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Upload Activity Thumbnail',
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Second Page',

      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: loginCallback, // Needs to match redirectUri in you oidcSettings
      name: 'signin-callback',
      component: OidcSigInCallBack,
    },
    {
      path: logoutCallback, // Needs to match popupRedirectUri in you oidcSettings
      name: 'OidcSignOutCallback',
      component: OidcSignOutCallBack,
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  var alreadyLoggedIn = router.app.$store.state.auth.IsLoggedIn;

  if (alreadyLoggedIn === null || alreadyLoggedIn === false)
  {
    oidc.isLoggedIn().then(auth => {
      alreadyLoggedIn = auth;

      // Might need more exploring if many pages will be ungated, just added the main ones to login process
      if (to.name !== 'login' && to.name !== 'add-new-case-public' && to.name !== 'case-video-public' && to.name !== 'OidcSignOutCallback' && to.name !== 'signin-callback' && !alreadyLoggedIn) {
        // we need to look at meta to assign roles to to veiw pages
        // console.log('to name ', to.name)
        console.log("Router is redirecting to login for auth required page");
        next('/')
      } else {
        next()
      }
    });
  } else {
    oidc.isLoggedIn();
    next();
  }

  
})
export default router
